import * as React from "react"

import {motion} from 'framer-motion'
import {scaleAni} from '../animation/animation'
function NoteIcon(props) {
  return (
<motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      variants={scaleAni}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
    </motion.svg>
  )
}

export default NoteIcon
