import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Page from "../noteEditor/page";
import SaveIcon from "../assets/saveIcon.js";
import BackIcon from "../assets/backIcon";
import EditIcon from "../assets/editIcon";
import DeleteIcon from "../assets/deleteIcon";
import { useHistory, useParams } from "react-router";
import Hotkeys from "react-hot-keys";

import axios from "axios";
import { SAVE_NOTE, GET_NOTE, DELETE_NOTE } from "../api";

import { motion } from "framer-motion";
import {
  darkBackgroundAni,
  fadeAni,
  fadeLeftAni,
  fadeLeftStruggerAni,
} from "../animation/animation";
import { useDispatch, useSelector } from "react-redux";

const StyledPage = styled(motion.div)`
  width: 100%;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(100, 100, 100, 0.2);
    border-radius: 4px;
    margin: 4rem 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(60, 60, 60, 0.8);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow-y: auto;
`;
const CenterPage = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const StyledNav = styled.div`
  z-index: 60;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 100px auto;
  top: 5vh;
  left: 10px;
  height: 90vh;
  border-radius: 10px;
  width: 60px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.3;
  transition: all 0.3s;
  flex-direction: column;
  transform: translateY(0px);

  transform: translateX(-30px);
  :hover {
    transform: translateX(0px);
    opacity: 1;
  }
  @media (max-width: 838px) {
    width: 90vw;
    height: 60px;
    left: 5vw;
    top: 10px;
    grid-template-rows: 1fr;
    grid-template-columns: 100px 1fr;
    justify-content: flex-start;
    transform: translateX(0px);

    transform: translateY(-30px);
    :hover {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;
const StyledBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  svg {
    transition: all 0.3s;
    cursor: pointer;
    width: 60%;
    margin: 10px;
    :hover {
      fill-opacity: 0.8;
    }
  }
  @media (max-width: 838px) {
    flex-direction: row;
    height: 60px;
    width: auto;
    margin: 0 1rem;

    svg {
      height: 60%;
      width: auto;
    }
  }
`;
const CenterNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  @media (max-width: 838px) {
    flex-direction: row;
    width: 100%;
  }
`;

const LastSave = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

const CreateNotePage = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const instanceRef = useRef(null);
  const history = useHistory();
  const { noteId } = useParams();
  const [data, setData] = useState(null);
  const [lastSave, setLastSave] = useState(new Date().toLocaleString());
  const { token } = useSelector((state) => state.user);

  const save = async () => {
    const savedData = await instanceRef.current.save();
    console.log(savedData);
    const body = {
      documentId: noteId,
      savedData: savedData,
    };
    try {
      const res = await axios.post(SAVE_NOTE(), body, {
        headers: { "auth-token": token },
      });
      setLastSave(new Date().toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    try {
      const res = await axios.get(GET_NOTE(noteId), {
        headers: { "auth-token": token },
      });
      const doc = res.data;
      const savedDoc = doc.savedData;
      if (!savedDoc) return setLoading(false);

      setData(savedDoc);
      setEdit(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteNote = async () => {
    try {
      await axios.delete(DELETE_NOTE(noteId), {
        headers: { "auth-token": token },
      });
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const saveShortcut = (k,e) => {
  e.preventDefault()
  if (!edit) {
    save();
  }
  };

  return (
    <StyledPage
      variants={fadeAni}
      initial="hide"
      animate="show"
    
    >
      <Hotkeys
      
        keyName="ctrl+s"
        filter={(event) => {
          return true;
        }}
        onKeyDown={saveShortcut}
  
      />
      <StyledNav>
        <StyledBlock>
          <BackIcon fill={"#616161"} onClick={() => history.goBack()} />
        </StyledBlock>
        <CenterNav>
          <StyledBlock>
            <SaveIcon
              fill={"#616161"}
              onClick={() => {
                if (!edit) {
                  save();
                }
              }}
            />
            <EditIcon
              fill={!edit ? "#54883c" : "#616161"}
              onClick={() => {
                setEdit(!edit);
                if (!edit) {
                  save();
                }
              }}
            />
          </StyledBlock>
          <StyledBlock>
            <DeleteIcon fill={"#d38282"} onClick={deleteNote} />
          </StyledBlock>
        </CenterNav>
      </StyledNav>
      {!loading && (
        <CenterPage variants={fadeAni}>
          <Page data={data} edit={edit} instanceRef={instanceRef} />
        </CenterPage>
      )}
      <LastSave>
        <p>{lastSave}</p>
      </LastSave>
    </StyledPage>
  );
};

export default CreateNotePage;
