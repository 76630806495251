import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Subject from "../components/subject";
import addIcon from "../assets/addIcon.svg";
import CreateSubject from "../components/createSubject";
import { useHistory } from "react-router";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getSubjectList } from "../action/subjectAction";

import {motion} from 'framer-motion'
import {scaleAni, straggerFade} from '../animation/animation'

const StyledContainer = styled(motion.div)`
  width: 100%;

  overflow-y: auto;
  display: inline-flex;
  gap: 1px;
  flex-wrap: wrap;
  padding: 5rem 10rem;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1024px) {
    padding: 5rem 0.2rem;
    justify-content: center;
    align-items: flex-start;
  }
`;

const StyledSubject = styled(motion.div)`
  width: 10rem;
  height: 10rem;
  min-width: 150px;
  min-height: 150px;
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  cursor: pointer;

  img,
  svg {
    width: 40%;
    margin: 0.5rem;
  }
`;

const SubjectPage = () => {
  const [createOpen, setCreateOpen] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { isLoading, subjectList } = useSelector((state) => state.subject);
  const history = useHistory();
  useEffect(() => {
   
    dispatch(getSubjectList(token));
    if(token == ""){
      history.push("/");
    }

  }, [token]);

  return (
    <>
      {createOpen && <CreateSubject open={setCreateOpen} />}
      <StyledContainer initial="hide" animate="show" variants={straggerFade}>

        {subjectList &&
          subjectList.map((sub) => (
            <Subject
            key={sub._id}
              id={sub._id}
              name={sub.subjectName}
              icon={sub.icon}
              teacher={sub.teacher}
            />
          ))}

        <StyledSubject variants={scaleAni} onClick={() => setCreateOpen(true)}>
          <img src={addIcon} alt="add" />
        </StyledSubject>
      </StyledContainer>
    </>
  );
};

export default SubjectPage;
