import { GET_NOTES_ULR,GET_PDF_ULR } from "../api";
import axios from "axios";

export const getNotesList = (jsToken,argumentId) => async (dispatch) => {
  //FETCH AXIOS


  dispatch({
    type: "NOTE_LOADING",
  });

  const notesRes = await axios.get(
    GET_NOTES_ULR(argumentId),
    { headers: { "auth-token": jsToken } }
  );

  const pdfRes = await axios.get(
    GET_PDF_ULR (argumentId),
    { headers: { "auth-token": jsToken } }
  );
  dispatch({
    type: "GET_NOTES",
    payload: {
        noteList: notesRes.data,
        pdfList: pdfRes.data,
    },
  });
};
