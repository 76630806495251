import * as React from "react"

function SaveIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    
      viewBox="0 0 24 24"
    
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
    </svg>
  )
}

export default SaveIcon
