export const showShadow = {
  hide: {
    "box-shadow": "0px 0px 0px 0px rgba(39, 39, 39, 0)",
  },
  show: {
    "box-shadow": "0px 0px 10px 8px rgba(39, 39, 39, 0.5)",
    transition: {
      delay: 2.5,
      duration: 0.7,
    },
  },
};
export const fadeAni = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeLeftAni = {
  hide: {
    x: -40,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
    },
  },
  hover:{
 
    "border-color": "rgba(255,255,255,1)",
    color: "rgba(255,255,255,1)",
    transition: {
        duration: 0.3,
        
      },
  }
};
export const fadeLeftStruggerAni = {
    hide: {
      x: -40,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      
        delayChildren: 0.2,
        staggerChildren: 0.2
      },
    },
  
  };

  export const noteNavAni = {
    hide: {
      x: -80,
      opacity: 0,
    },
    show: {
      x: -20,
      opacity: 1,
      transition: {
        duration: 0.5,
      
        delayChildren: 0.2,
        staggerChildren: 0.2
      },
    },

  
  };

export const straggerFade = {
  hide: {
  
  },
  show: {
    
    transition: {
      when: "beforeChildren",
      delayChildren: 0.3,
      staggerChildren: 0.2
    },
  },
};

export const logoAni = {
    hide: {
    
        pathLength: 0,
        fill: "rgba(255,255,255,0)"

      },
      show: {
        pathLength: 1,
        fill: "rgba(255,255,255,1)",
        transition: {
         duration: 4,
        },
      },
}
export const backgroundAni = {
    hide:{
        background: "rgba(255,255,255,1)"
    },
    show:{
        background: "rgba(48, 48, 48,1)"
    }
}


export const scaleAni = {
    hide:{
        scale: 0.3,
        opacity: 0,
    },
    show:{
        scale: 1,
        opacity: 1,
        transition: {
            duration: 0.6,
           },
    }
}

export const darkBackgroundAni ={
    hide:{
        background: "rgba(0, 0, 0, 0)"
    },
    show:{
        background: "rgba(0, 0, 0, 0.1)"
    },
    leave:{
        background: "rgba(0, 0, 0, 0)",
        transition: {
            duration: 0.6,
           },
    }
}