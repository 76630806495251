const base_url = "https://www.boscaro.cloud/api";
export const LOGIN_ULR = () => base_url + "/user/login"
export const ADD_SUBJECT_ULR = () => base_url + "/addSubject"
export const GET_SUBJECT_ULR = () => base_url + "/getSubject"
export const ADD_ARGUMENT_ULR = () => base_url + "/addArgument"
export const GET_NOTES_ULR = (argumentId) => base_url + `/notes/${argumentId}`
export const GET_PDF_ULR = (argumentId) => base_url + `/pdf/${argumentId}`
export const POST_FILE = () => base_url + `/upload`
export const GET_FILE = (filename) => base_url + `/file/${filename}`
export const GET_IMAGE = (imageName) => base_url + `/image/${imageName}`
export const UPLOAD_IMAGE = () => base_url + `/image/upload`
export const CREATE_NOTE = () => base_url + `/note/create`
export const SAVE_NOTE = () => base_url + `/note/save`
export const GET_NOTE = (docId) => base_url + `/note/${docId}`
export const DELETE_NOTE = (docId) => base_url + `/note/delete/${docId}`