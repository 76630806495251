import React, { useState } from "react";
import styled from "styled-components";

import axios from "axios";
import { ADD_SUBJECT_ULR } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectList } from "../action/subjectAction";


import {motion} from 'framer-motion'
import {darkBackgroundAni, fadeAni,fadeLeftAni,} from '../animation/animation'

const StyledBackground = styled(motion.div)`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled(motion.form)`
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  min-width: 600px;
  max-width: 800px;
  height: 40vh;
  max-height: 500px;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  input[type="text"] {
    width: 300px;
    height: 30px;
    border: 1px solid #ffffff84;
letter-spacing: 1px;
    border-radius: 5px;
    margin: 1rem 0;
    padding: 5px;
    color: white;
    background: transparent;
    outline: none;
    fill: white;
    ::-ms-reveal {
      filter: invert(100%);
    }
  
  }
  p {
    color: rgba(248, 118, 118, 0.623);
    font-weight: 200;
  }
  input[type="submit"] {
    width: 60px;
    height: 30px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #ffffff84;
    border-radius: 0;
    color: #ffffff84;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;

  }
  label {
    color: #ffffff84;
    letter-spacing: 1px;
    font-weight: 200;
  }
  div {
    display: flex;
    justify-content: flex-end;
    width: 300px;
  }

  @media (max-width: 650px) {
    min-width: 80vw;
    min-height: 80vh;
    flex-direction: column;
    div {
      justify-content: center;
    }
    input[type="text"] {
      width: 80%;
    }
  }
`;

const CreateSubject = ({ open }) => {
  const [subjectName, setSubjectName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [error, setError] = useState("");
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    if (!subjectName) return setError("Insert subject name");
    const body = {
      subjectName: subjectName,
      teacher: teacherName,
      icon: "",
    };

    axios
      .post(ADD_SUBJECT_ULR(), body, { headers: { "auth-token": token } })
      .then((res) => {
        dispatch(getSubjectList(token));
        open(false);
      })
      .catch((err) => setError(err.response.data));
  };

  const closeHandler = e =>{
    const id = e.target.id;
    if (id === "background"){
      open(false);
    }


  }
  return (
    <StyledBackground initial="hide" animate="show" variants={darkBackgroundAni} id="background" exit="leave" onClick={closeHandler}>
      <StyledForm  onSubmit={submitHandler} variants={fadeAni}>
      <p>{error}</p>
        <motion.label variants={fadeLeftAni}>Subject Name</motion.label>
        <motion.input  variants={fadeLeftAni} whileFocus="hover" whileHover="hover" type="text" onChange={(e) => setSubjectName(e.target.value)} />
        <motion.label  variants={fadeLeftAni}>Teacher</motion.label>
        <motion.input  variants={fadeLeftAni} whileHover="hover"  whileFocus="hover"   type="text" onChange={(e) => setTeacherName(e.target.value)} />
        
        <motion.input  whileHover="hover"  whileFocus="hover" variants={fadeLeftAni} type="submit" value="add" />
        
      </StyledForm>
    </StyledBackground>
  );
};

export default CreateSubject;
