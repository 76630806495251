import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import {motion} from 'framer-motion'
import {fadeAni, scaleAni, straggerFade} from '../animation/animation'

const StyledArgument = styled(motion.div)`
   width: 90%;
  padding: 0 1rem;
  margin: 1rem 0;
  
  height: 70px;
  max-width: 600px;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  h3 {
    color: white;
    font-weight: 300;
    letter-spacing: 1px;
  }
`;

const Argument = ({ id, name }) => {

const history = useHistory()
const clickHandler = () =>{
    history.push(`/argument/${id}`)
}
  return (
    <StyledArgument onClick={clickHandler} variants={scaleAni}>
      <h3>{name}</h3>
    </StyledArgument>
  );
};

export default Argument;
