import React from "react";
import styled from "styled-components";

const StyledBackground = styled.div`
  position: absolute;
  background: #383838;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 50%;
    fill: white;
  }
`;

const LandPage = () => {
  return (
    <StyledBackground>
      <svg
        viewBox="0 0 422 651"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
      >
        <path d="M195.735 399.766v-40.544l-2.454-2.454h-4.908l-2.454 2.454v81.241h-15.973l-2.454 2.454v4.908l2.454 2.454h41.761l2.454-2.454v-4.908l-2.454-2.454h-15.972v-30.811c19.815.177 73.728.586 87.44-.07 2.716-.13 5.681-2.71 6.559-4.851 5.929-14.46 39.124-96.227 39.124-96.227s-39.075-68.536-39.124-82.758v-.005c.433-16.23 39.124-96.227 39.124-96.227s-34.424-69.335-39.124-82.758c-1.595-4.553-7.907-4.908-7.907-4.908h-86.092V9.816h15.972l2.454-2.454V2.454L211.707 0h-41.761l-2.454 2.454v4.908l2.454 2.454h15.973v81.241l2.454 2.454h4.908l2.454-2.454V51.664h84.182l38.796 79.19-38.796 89.922h-84.182v-62.083a4.91 4.91 0 00-4.908-4.908 4.91 4.91 0 00-4.908 4.908v133.981a4.91 4.91 0 004.908 4.908 4.91 4.91 0 004.908-4.908v-62.02h84.182l38.796 79.19-38.796 89.922h-84.182zm34.959-290.453l-94.426-.069c-2.709 0-4.268 3.607-4.908 6.001-5.122 19.174-39.125 117.643-39.125 117.643s32.835 84.483 39.125 101.177c2.545 6.755 7.28 5.751 11.417 6 16.002.964 83.094.07 87.917.004v8.789l2.454 2.454h4.908l2.454-2.454v-34.184l-2.454-2.454h-4.908l-2.454 2.454v13.391h-89.518l-38.796-96.816 38.796-109.934h89.518v10.556l2.454 2.454h4.908l2.454-2.454V97.687l-2.454-2.454h-4.908l-2.454 2.454v11.626z" />
        <path
          d="M73.926 487.663c12.304 0 19.987 4.525 23.047 13.574v2.93c0 9.375-9.278 23.177-27.832 41.406-17.839 18.034-29.427 28.483-34.766 31.348-7.943 6.901-11.914 10.645-11.914 11.23v.684c0 1.107 6.706 3.255 20.117 6.445 4.362 2.344 6.934 4.916 7.715 7.715 0 7.617-14.388 22.591-43.164 44.922-2.669 1.563-4.46 2.344-5.371 2.344h-.586c-.781 0-1.172-.586-1.172-1.758 0-.846 10.449-10.514 31.348-29.004 6.054-7.226 9.798-12.337 11.23-15.332 0-2.995-4.525-5.176-13.574-6.543 0-.391-.586-.586-1.758-.586h-4.785c-3.32-1.367-5.664-4.329-7.031-8.887 0-3.125 8.073-10.807 24.218-23.046 34.31-28.907 51.465-50.586 51.465-65.039-3.125-3.516-7.877-5.274-14.258-5.274h-8.3c-22.787 3.451-34.18 8.171-34.18 14.16l-16.602 58.496c-6.315 29.297-9.472 44.466-9.472 45.508-1.042 1.953-2.442 2.93-4.199 2.93h-.586c-.782 0-1.172-.391-1.172-1.172 4.362-26.693 11.686-57.617 21.972-92.773 2.344-7.097 3.907-13.607 4.688-19.532.781 0 1.758-1.562 2.93-4.687 18.88-6.706 32.877-10.059 41.992-10.059zM64.746 584.831c.456-3.711 1.628-7.487 3.516-11.328l-.391-.781c.195-1.042.456-1.563.781-1.563l-.39-.781c0-.456 1.562-.977 4.687-1.562.716-1.303 1.888-1.954 3.516-1.954 3.125 2.605 5.859 3.907 8.203 3.907l-1.953 5.078-2.735-1.563c0 3.646-2.213 6.511-6.64 8.594-1.693-.521-2.995-1.172-3.906-1.953 0 .716-1.563 2.018-4.688 3.906z"
          fillRule="nonzero"
        />
        <path
          d="M98.438 563.444c1.562 1.042 2.343 2.214 2.343 3.516-.521 1.563-1.562 2.344-3.125 2.344-2.474-.781-4.166-1.172-5.078-1.172-2.344.781-3.516 1.432-3.516 1.953 3.907 4.232 5.86 6.445 5.86 6.641v.39c0 2.019-1.823 3.581-5.469 4.688-2.864-.521-4.297-1.042-4.297-1.563v-.39l3.906-2.735v-.39c-3.125-3.451-4.687-6.315-4.687-8.594 0-3.125 4.687-4.688 14.062-4.688zM115.234 563.737c.782.847 1.172 1.498 1.172 1.954 0 .911-2.734 2.213-8.203 3.906-3.646 2.669-5.469 4.752-5.469 6.25.847.521 1.498.781 1.953.781.782 0 4.297-.391 10.547-1.172h1.172c.521.065.781.326.781.781 0 2.084-4.036 3.516-12.109 4.297-2.474 0-4.948-1.692-7.422-5.078v-.39c0-1.758 2.344-4.102 7.031-7.032 3.451-2.864 6.967-4.297 10.547-4.297z"
          fillRule="nonzero"
        />
        <path
          d="M137.207 576.726h-3.223c-1.367 0-3.287-1.075-5.761-3.223h-.586c-3.06 3.19-5.502 4.785-7.324 4.785h-1.075l-4.199-3.711c0-4.883 2.279-8.561 6.836-11.035h2.148c6.641.456 9.961 1.856 9.961 4.199v2.149h.586c.586 0 1.465 2.278 2.637 6.836z"
          fillRule="nonzero"
        />
        <path
          d="M152.637 564.421c1.041 0 1.953.651 2.734 1.953v1.172c0 .26-2.344 1.953-7.031 5.078-5.078 5.729-8.724 8.594-10.938 8.594-1.041 0-1.953-1.563-2.734-4.688l.391-1.953c-.261-1.432-.391-2.604-.391-3.515.521-1.172 1.042-1.823 1.562-1.954h1.172c2.084 0 3.125.066 3.125.196v1.172h.391c6.445-4.037 10.352-6.055 11.719-6.055zM153.125 584.831c.456-3.711 1.628-7.487 3.516-11.328l-.391-.781c.195-1.042.456-1.563.781-1.563l-.39-.781c0-.456 1.562-.977 4.687-1.562.716-1.303 1.888-1.954 3.516-1.954 3.125 2.605 5.859 3.907 8.203 3.907l-1.953 5.078-2.735-1.563c0 3.646-2.213 6.511-6.64 8.594-1.693-.521-2.995-1.172-3.906-1.953 0 .716-1.563 2.018-4.688 3.906zM305.469 479.948c8.463 2.279 12.695 5.567 12.695 9.864v.488c0 10.677-4.85 18.75-14.551 24.219-.911 0-1.367.162-1.367.488-2.604-.651-4.46-2.083-5.566-4.297 0-1.758 2.636-3.776 7.91-6.055 4.362-.52 6.836-5.143 7.422-13.867v-.976c0-1.888-.912-3.321-2.735-4.297-15.494 0-35.547 11.621-60.156 34.863-10.351 10.156-18.001 19.206-22.949 27.148-9.44 14.063-14.616 26.14-15.527 36.231 0 9.896 6.868 16.601 20.605 20.117 3.125.651 5.957.977 8.496.977h3.809c15.494 0 34.244-7.65 56.25-22.95 1.562-.781 2.343-1.269 2.343-1.464 1.237.455 1.856.944 1.856 1.464-7.227 7.813-17.383 14.681-30.469 20.606-11.458 4.362-20.215 6.543-26.269 6.543h-5.176l-3.32-.488v.488c-10.027-1.042-19.271-4.655-27.735-10.84-3.711-5.013-5.566-9.7-5.566-14.062 0-11.915 7.194-27.54 21.582-46.875 6.25-8.334 14.844-17.416 25.781-27.247 10.156-9.375 18.783-15.625 25.879-18.75 1.237-1.302 6.413-3.971 15.527-8.007.716-.847 4.46-1.953 11.231-3.321z"
          fillRule="nonzero"
        />
        <path
          d="M316.602 540.398h1.562c.326 0 .977.651 1.953 1.953v.39l-10.547 33.594v1.172h.782c3.971-4.688 6.575-7.031 7.812-7.031.846 0 2.93 1.432 6.25 4.297 2.083 0 3.125.651 3.125 1.953-.456 1.172-1.237 2.083-2.344 2.734-.651 0-2.994-1.172-7.031-3.516-5.664 5.925-8.789 8.92-9.375 8.985h-2.344c-.325 0-1.106-.912-2.343-2.735 0-3.125 3.906-16.796 11.718-41.015.065-.521.326-.781.782-.781z"
          fillRule="nonzero"
        />
        <path
          d="M339.648 564.421c1.042 0 1.954.651 2.735 1.953v1.172c0 .26-2.344 1.953-7.031 5.078-5.079 5.729-8.724 8.594-10.938 8.594-1.042 0-1.953-1.563-2.734-4.688l.39-1.953c-.26-1.432-.39-2.604-.39-3.515.521-1.172 1.041-1.823 1.562-1.954h1.172c2.083 0 3.125.066 3.125.196v1.172h.391c6.445-4.037 10.351-6.055 11.718-6.055zM343.75 559.538c1.563.586 2.344 1.628 2.344 3.125-.391 1.302-1.302 1.953-2.735 1.953h-.39c-.586 0-1.237-1.041-1.953-3.125.39-1.302 1.302-1.953 2.734-1.953zm-1.172 9.375c1.563 1.042 2.344 2.084 2.344 3.125-1.823 4.623-3.125 7.227-3.906 7.813h-1.172c-.781-.261-1.172-1.042-1.172-2.344v-.781c.456-5.209 1.497-7.813 3.125-7.813h.781zM361.523 563.444c1.563 1.042 2.344 2.214 2.344 3.516-.521 1.563-1.562 2.344-3.125 2.344-2.474-.781-4.166-1.172-5.078-1.172-2.344.781-3.516 1.432-3.516 1.953 3.907 4.232 5.86 6.445 5.86 6.641v.39c0 2.019-1.823 3.581-5.469 4.688-2.865-.521-4.297-1.042-4.297-1.563v-.39l3.906-2.735v-.39c-3.125-3.451-4.687-6.315-4.687-8.594 0-3.125 4.687-4.688 14.062-4.688z"
          fillRule="nonzero"
        />
        <path
          d="M382.227 529.558c1.432 0 2.343.521 2.734 1.562-.26 1.823-4.167 10.938-11.719 27.344-1.562 2.344-3.125 5.859-4.687 10.547l.781.39 2.734-.39c1.628 0 2.8.651 3.516 1.953v.781c0 .651-3.125 1.693-9.375 3.125-1.888 9.636-3.581 14.453-5.078 14.453h-.781c0-.651-.521-1.041-1.563-1.172v-1.171c0-1.368 1.172-6.055 3.516-14.063-.521-.846-.782-1.497-.782-1.953 2.605-1.107 3.907-3.32 3.907-6.641 6.836-14.257 11.914-25.846 15.234-34.765h1.563zM380.859 559.538c1.563.586 2.344 1.628 2.344 3.125-.39 1.302-1.302 1.953-2.734 1.953h-.391c-.586 0-1.237-1.041-1.953-3.125.391-1.302 1.302-1.953 2.734-1.953zm-1.171 9.375c1.562 1.042 2.343 2.084 2.343 3.125-1.823 4.623-3.125 7.227-3.906 7.813h-1.172c-.781-.261-1.172-1.042-1.172-2.344v-.781c.456-5.209 1.498-7.813 3.125-7.813h.782zM407.227 576.726h-3.223c-1.367 0-3.288-1.075-5.762-3.223h-.586c-3.06 3.19-5.501 4.785-7.324 4.785h-1.074l-4.199-3.711c0-4.883 2.278-8.561 6.836-11.035h2.148c6.641.456 9.961 1.856 9.961 4.199v2.149h.586c.586 0 1.465 2.278 2.637 6.836z"
          fillRule="nonzero"
        />
        <path
          d="M413.281 564.519c3.321 0 5.664 1.953 7.032 5.859 0 .521.26 1.823.781 3.906-.261 1.042-.912 1.563-1.953 1.563-.717 0-2.019-1.953-3.907-5.86h-.781c-1.953 0-4.167 2.214-6.64 6.641-.782.26-1.172.521-1.172.781-2.605-.651-3.907-1.823-3.907-3.515 5.664-5.99 9.18-9.115 10.547-9.375z"
          fillRule="nonzero"
        />
      </svg>
    </StyledBackground>
  );
};

export default LandPage;
