  
import { combineReducers } from "redux";


//import reducer
import userReducer  from "./userReducer";
import subjectReducer from './subjectReducer'
import docsReducer from "./docsReducer";

const rootReducer = combineReducers({
    user : userReducer,
    subject: subjectReducer,
    docs: docsReducer,
});

export default rootReducer;