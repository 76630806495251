import React, { useState, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GET_IMAGE, UPLOAD_IMAGE } from "../api";
import EditorJs from "react-editor-js";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import Formula from './formulaBlock'

import axios from "axios";

const StyledPage = styled.div`
  border-radius: 20px;
  margin: 10vh 0;
  padding: 2rem;
  min-height: 80vh;
  max-width: 1400px;
  width: 80%;
  background: transparent;
  overflow: hidden;
  display: block;
  height: auto;

  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  .ce-block__content,
  .ce-toolbar__content {
    max-width: unset;
    width: 95%;
    margin: 0 1rem;
  }
  .cdx-simple-image__picture--with-background {
    background: rgba(60, 60, 60, 0.3);
  }
  .cdx-simple-image__picture--with-border {
    border-color: rgba(60, 60, 60, 0.3);
  }
  .cdx-checklist__item-checkbox {
    background: rgba(60, 60, 60, 0.3);
    border-color: rgba(60, 60, 60, 1);
    :hover {
      background: #ac77dd;
    }
    :after {
      border-color: #77dd96;
      left: 4px;
      top: 5px;
    }
  }
  .cdx-block {
    color: white;
    letter-spacing: 1px;
    ::selection {
      color: white;
      background: rgba(60, 60, 60, 0.8);
    }
  }
  .ce-settings__default-zone {
    background: rgba(32, 32, 32, 0.7);
  }
  .ce-toolbar__settings-btn {
    background: rgba(100, 100, 100, 0.2);
    border-radius: 2px;
    svg {
      fill: rgba(255, 255, 255, 0.6);
    }
  }
  .ce-settings {
    border-color: rgba(32, 32, 32, 0.7);
  }
  .ce-block--selected {
    background: rgba(100, 100, 100, 0.2);
  }
  .ce-block__content {
    background: transparent;
  }
  .ce-block--selected {
    background: rgba(100, 100, 100, 0.2);
  }
  .codex-editor--narrow .ce-toolbox {
    background: rgba(40, 40, 40, 1);
    border-radius: 3px;
  }
  .ce-conversion-toolbar {
    background: rgba(40, 40, 40, 1);
    border: rgba(40, 40, 40, 1);
  }
  .icon {
    fill: white;
  }
  .ce-inline-toolbar__dropdown {
    :hover {
      background: rgba(60, 60, 60, 1);
      border-radius: 5px;
    }
  }
  .ce-inline-toolbar {
    background: rgba(40, 40, 40, 1);
    border-color: rgba(40, 40, 40, 1);
    color: white;
  }
  .ce-conversion-tool__icon {
    background: rgba(40, 40, 40, 0.5);
    border: rgba(40, 40, 40, 0.5);

    svg {
      fill: rgba(100, 100, 100, 1);
    }
  }
  .ce-conversion-tool {
    color: rgba(100, 100, 100, 1);
  }
  .ce-inline-tool {
  svg{
    fill: white;
  }
    :hover {
      background: rgba(60, 60, 60, 1);
    }
  }
   .ce-inline-tool--active{
    svg{
    fill: #ac77dd;
  }
  }
  .ce-code__textarea {
    background: #272727;
    border-color: #272727;
    color: #ac77dd;
  }
  .ce-header {
    color: white;
    letter-spacing: 2px;
  }
  .ce-settings {
    background: #272727;
    border-color: #272727;
  }
  .cdx-settings-button {
    svg {
      fill: white;
    }
    :hover {
      background: rgba(60, 60, 60, 1);
      border-radius: 5px;
    }
  }
  .cdx-settings-button--active {
 
    svg {
      fill: #d856b1;
    }
  }
  .inline-code{
    background: #272727;
    
    color: #ac77dd;
  }
  .ce-conversion-tool:hover {
    background: rgba(119, 119, 119, 0.5);
}
  @media (max-width: 650px) {
    width: 90vw;

    .ce-toolbar {
      background: rgba(100, 100, 100, 1);
      border-color: rgba(100, 100, 100, 1);
    }
  }
`;

const Page = ({ edit, instanceRef, data }) => {
  const { token } = useSelector((state) => state.user);

  async function uploadByFile(file) {
    try {
      const form = new FormData();
      form.append("image", file);
      const res = await axios.post(UPLOAD_IMAGE(), form, {
        headers: { "content-type": "multipart/form-data", "auth-token": token },
      });
      console.log(res.data);
      const imageUrl = GET_IMAGE(res.data);

      return {
        success: 1,
        file: {
          url: imageUrl,
        },
      };
    } catch (error) {
      return {
        success: 0,
        file: {
          url: "",
        },
        config: {},
      };
    }
  }

   const dataTest = {
    blocks: [
      {
        type: 'header',
        data: {
          text: 'Editor.js',
          level: 2,
        },
      },
      {
        type: 'paragraph',
        data: {
          text:
            'Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.',
        },
      },
      {
        type: 'react',
        data: {
          text:
            "k_{n+1} = n^2 + k_n^2 - k_{n-1}"
        },
      },

      
    ],
  }

  

  const EDITOR_JS_TOOLS = {

    header: Header,
    formula: Formula,
    raw: Raw,
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    embed: Embed,
    table: Table,
    list: List,
    warning: Warning,
    code: Code,
    image: {
      class: Image,

      config: {
        uploader: { uploadByFile: uploadByFile },
      },
    },
    linkTool: LinkTool,

  };

  return (
    <StyledPage>
      <EditorJs
   
        instanceRef={(instance) => (instanceRef.current = instance)}
        readOnly={edit}
        data={data}
      
  
        tools={EDITOR_JS_TOOLS}
      />
    </StyledPage>
  );
};

export default Page;
