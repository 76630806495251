import LandPage from './pages/landPage';
import SubjectPage from './pages/subjectPage';
import ArgomentPage from './pages/argomentPage'
import Login from './pages/loginPage'
import ArchivePage from './pages/archivePage';
import PdfPage from './pages/pdfPage';
import CreateNotePage from './pages/createNotePage';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, Link,useLocation  } from "react-router-dom";

function App() {
  return (
    <div className="App">
       <AnimatePresence exitBeforeEnter>
      <Switch>

      <Route exact path="/" component={Login}/>
      <Route exact path="/subject" component={SubjectPage}/>
      <Route exact path="/subject/:id" component={ArgomentPage}/>
      <Route exact path="/argument/:id" component={ArchivePage}/>
      <Route exact path="/pdf/:id" component={PdfPage}/>
      <Route exact path="/argument/:id/note/:noteId" component={CreateNotePage}/>
         

 
      </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
