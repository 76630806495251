const initialState = {
    isLoading: false,
    subjectList:[],
  };
  
  const subjectReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SUBJECT":
        return {
          ...state,
          isLoading: false,
          subjectList: action.payload.subjectList
        };
        case "SUBJECT_LOADING":
        return{
          ...state,
          isLoading: true,
        };

      default:
        return { ...state };
    }
  };
  
  export default subjectReducer;