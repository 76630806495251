import React, { useState,useEffect } from "react";
import styled from "styled-components";

import Argument from "../components/argument";
import addIcon from "../assets/addIcon.svg";
import CreateArgument from "../components/createArgument";

import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {motion} from 'framer-motion'
import {fadeAni, scaleAni, straggerFade} from '../animation/animation'

const StyledArguments = styled(motion.div)`
  display: flex;

  align-items: center;
  height: 50vh;
  width: 30vw;
  min-width: 650px;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 90vw;
    min-width: auto;
    height: 80vh;
  }
`;
const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const StyledArgument = styled(motion.div)`
  max-width: 600px;
  width: 90%;

  margin: 1rem 0;
  height: 70px;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding: 0rem 0;
    height: 2rem;
  }
`;


const ArgomentPage = () => {
  const [createOpen, setCreateOpen] = useState(false);
const {id} = useParams()
const history = useHistory();
  const { subjectList } = useSelector((state) => state.subject);
  const { token, isLoading, loginError } = useSelector((state) => state.user);


  useEffect(() => {
    if (token == "") {
      history.push("/subject");
    }
  }, [token]);
  return (
    <>
      {createOpen && <CreateArgument open={setCreateOpen} />}
      <StyledPage>
        <StyledArguments variants={straggerFade} initial="hide" animate="show" >
          {subjectList.filter(sub => sub._id == id)[0].arguments.map((argument) => (
            <Argument key={argument._id} id={argument._id} name={argument.argumentName} />
          ))}
          <StyledArgument variants={scaleAni} onClick={() => setCreateOpen(true)}>
            <img src={addIcon} alt="add" />
          </StyledArgument>
        </StyledArguments>
      </StyledPage>
    </>
  );
};

export default ArgomentPage;
