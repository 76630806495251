import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router";

import PreviewFile from "../components/previewFile";
import PreviewNote from "../components/previewNote";

import ArchiveNav from "../components/archiveNav";
import UploadPdf from "../components/uploadPdf";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getNotesList } from "../action/noteAction";

import { motion } from "framer-motion";
import {
  darkBackgroundAni,
  fadeAni,
  fadeLeftAni,
  fadeLeftStruggerAni,
  straggerFade,
} from "../animation/animation";

const StyledPage = styled(motion.div)`
  width: 100vw;
  padding: 5rem 10rem;

  height: 100vh;
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(100, 100, 100, 0.2);
    border-radius: 4px;
    margin: 4rem 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(60, 60, 60, 0.8);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow-y: auto;
  @media (max-width: 1025px) {
    padding: 2.5rem 5rem;
  }
  @media (max-width: 838px) {
    padding: 60px 1rem;
  }
`;

const StyledBlock = styled.div`
  width: 100%;

  main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 80vh;
  }
  @media (max-width: 838px) {
    main {
      justify-content: space-around;
    }
  }
`;
const StyledBlockTitle = styled.div`
  padding-left: 1rem;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h3 {
    color: white;
    letter-spacing: 3px;
    font-weight: 300;
  }
`;

const ArchivePage = () => {
  const [uploadPdf, setUploadPdf] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { id } = useParams();
  const { isLoading, noteList, pdfList } = useSelector((state) => state.docs);
  const history = useHistory();

  useEffect(() => {
    dispatch(getNotesList(token, id));

    if (token == "") {
      history.push("/");
    }
  }, [token]);

  return (
    <>
      {uploadPdf && <UploadPdf open={setUploadPdf} />}
      <ArchiveNav openUploadPdf={setUploadPdf} />
      <StyledPage initial="hide" animate="show" variants={fadeLeftStruggerAni}>
        <StyledBlock>
          <StyledBlockTitle>
            <h3>Notes:</h3>
          </StyledBlockTitle>


    {      (!isLoading &&
              <motion.main
              initial="hide"
              animate="show"
              variants={fadeLeftStruggerAni}
            >
             {noteList.map(note =>   <PreviewNote key={note._id} name={note.title} docId={note._id} />)}
              
            </motion.main>
              )}

         
        </StyledBlock>
        <StyledBlock>
          <StyledBlockTitle>
            <h3>PDF:</h3>
          </StyledBlockTitle>
          {
            (!isLoading &&
              <motion.main
              initial="hide"
              animate="show"
              variants={fadeLeftStruggerAni}
            >
             {pdfList.map(pdf =>   <PreviewFile  key={pdf.documentName} name={pdf.title} id={pdf.documentName} />)}
              
            </motion.main>
              )
          }
       
        </StyledBlock>
      </StyledPage>
    </>
  );
};

export default ArchivePage;
