import React from 'react';
import styled from 'styled-components'
import defaultIcon from '../assets/defaultIcon.svg';
import { useHistory } from 'react-router';

import {motion} from 'framer-motion'
import {scaleAni} from '../animation/animation'


const StyledSubject = styled(motion.div)`
    width: 10rem; 
    height: 10rem;
    min-width: 150px;
    min-height: 150px;
    overflow: hidden;
    border-radius: 1rem;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem ;
    cursor: pointer;
    color:white;
    letter-spacing: 1px;
    h4{
        font-weight: 300;
        text-align: center;
    }
    h5{
        font-weight: 200;
    font-size: 0.6rem;
    }
   
    img{
        width: 50%;
        margin: 0.5rem;
    
    }

`


const Subject = ({id, name,icon,teacher}) =>{
    const history = useHistory();
    const clickHandler = () =>{
        history.push(`/subject/${id}`);
    }
    return(
        <StyledSubject variants={scaleAni} onClick={clickHandler}>
             <h4>{name}</h4>
           <img src={icon ? icon : defaultIcon} alt="" />
           
            <h5>{teacher}</h5>
        </StyledSubject>
    )
}

export default Subject;