const initialState = {
    isLoading: false,
    pdfList:[],
    noteList:[],
  };
  
  const  noteReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_NOTES":
        return {
          ...state,
          isLoading: false,
          pdfList: action.payload.pdfList,
          noteList: action.payload.noteList
        };
        case "NOTE_LOADING":
        return{
          ...state,
          isLoading: true,
        };

      default:
        return { ...state };
    }
  };
  
  export default noteReducer;