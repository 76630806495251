import * as React from "react"
import {motion} from 'framer-motion'
import {scaleAni} from '../animation/animation'

function BackIcon(props) {
  return (
    <motion.svg
    variants={scaleAni}
      xmlns="http://www.w3.org/2000/svg"
    
      viewBox="0 0 24 24"
     
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17.77 3.77L16 2 6 12l10 10 1.77-1.77L9.54 12z" />
    </motion.svg>
  )
}

export default BackIcon
