import React from "react";
import styled from "styled-components";
import defaultIcon from "../assets/defaultIcon.svg";
import { useHistory,useParams } from "react-router";


import {motion} from 'framer-motion'
import {darkBackgroundAni, fadeAni,fadeLeftAni,fadeLeftStruggerAni,} from '../animation/animation'


const StyledSubject = styled(motion.div)`
  width: 12rem;
  height: 15rem;
  min-width: 150px;
  min-height: 150px;
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  h4 {
    font-weight: 300;
  }
  h5 {
    font-weight: 200;
  }
  header {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  main {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 60%;
    margin: 0.5rem;
  }

  @media (max-width: 838px){
    width: 50px;
    height: 200px;
  }
`;

const PreviewNote = ({ docId, name, icon }) => {
  const history = useHistory();
  const {id} = useParams();

  const clickHandler = () => {
    history.push(`/argument/${id}/note/${docId}`);
  };
  return (
    <StyledSubject  onClick={clickHandler} variants={fadeLeftAni}>
      <header>
        <h4>{name}</h4>
      </header>
      <main>
        <img src={icon ? icon : defaultIcon} alt="" />
      </main>
    </StyledSubject>
  );
};

export default PreviewNote;
