import React, { useState, useRef } from "react";
import styled from "styled-components";

import axios from "axios";
import { POST_FILE } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { getNotesList } from "../action/noteAction";
import { useHistory, useParams } from "react-router-dom";

import { motion } from "framer-motion";
import {
  darkBackgroundAni,
  fadeAni,
  fadeLeftAni,
} from "../animation/animation";

const StyledBackground = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const StyledForm = styled(motion.form)`
  background: #222222;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  min-width: 600px;
  max-width: 800px;
  height: 40vh;
  max-height: 500px;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  input[type="text"] {
    width: 300px;
    height: 30px;
    border: 1px solid #ffffff84;

    border-radius: 5px;
    margin: 1rem 0;
    padding: 5px;
    color: white;
    background: transparent;
    outline: none;
    fill: white;
    ::-ms-reveal {
      filter: invert(100%);
    }
  }
  p {
    color: rgba(248, 118, 118, 0.623);
    font-weight: 200;
  }
  input[type="submit"] {
    width: 60px;
    height: 30px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #ffffff84;
    border-radius: 0;
    color: #ffffff84;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    :hover {
      border-color: #fff;
      color: #fff;
    }
  }
  label {
    color: #ffffff84;
    letter-spacing: 1px;
    font-weight: 200;
  }
  div {
    display: flex;
    justify-content: flex-end;
    width: 300px;
  }
  button {
    background: transparent;
    border: none;
    width: 300px;
    height: 30px;
    border-radius: 0;
    color: #ffffff84;
    border: 1px solid #ffffff84;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    margin: 10px 0;
  }
  @media (max-width: 650px) {
    min-width: 80vw;
    min-height: 80vh;
    flex-direction: column;
    div {
      justify-content: center;
    }
    input[type="text"] {
      width: 80%;
    }
  }
`;

const UploadPdf = ({ open }) => {
  const [filePath, setFilePath] = useState(null);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { id } = useParams();
  const inputFile = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!filePath || !fileName)
      return setError("Insert file name or select file");
    const data = new FormData();
    data.append("file", filePath);

    data.append("fileType", "pdf");
    data.append("title", fileName);
    data.append("argumentId", id);

    axios
      .post(POST_FILE(), data, {
        headers: {  "auth-token": token,  'content-type': 'multipart/form-data','Access-Control-Allow-Origin': 'origin' },
      })
      .then((res) => {
        dispatch(getNotesList(token, id));
        open(false);
      })
      .catch((err) => console.log(err));
  };

  const UplodadHandler = () => {
    inputFile.current.click();
  };
  const closeHandler = (e) => {
    const id = e.target.id;
    if (id === "background") {
      open(false);
    }
  };
  return (
    <StyledBackground
      variants={darkBackgroundAni}
      initial="hide"
      animate="show"
      exit="leave"
      id="background"
      onClick={closeHandler}
    >
      <StyledForm variants={fadeAni} onSubmit={submitHandler}>
        <input
          type="file"
          id="file"
          name="file"
          ref={inputFile}
          onChange={(e) => setFilePath(e.target.files[0])}
          multiple="none"
          style={{ display: "none" }}
          accept="application/pdf,application/vnd.ms-excel"
        />
        <motion.p variants={fadeLeftAni}>{error}</motion.p>
        <label>File Name</label>
        <motion.input
          variants={fadeLeftAni}
          whileFocus="hover"
          whileHover="hover"
          type="text"
          onChange={(e) => setFileName(e.target.value)}
        />
        <label
          style={{
            color: filePath == null ? "#ffffff84" : "#62aa7884",
          }}
        >
          File
        </label>
        <motion.button
          variants={fadeLeftAni}
          whileFocus="hover"
          whileHover="hover"
          type="button"
          onClick={UplodadHandler}
        >
          Select File
        </motion.button>

        <motion.input
          variants={fadeLeftAni}
          whileFocus="hover"
          whileHover="hover"
          type="submit"
          value="add"
        />
      </StyledForm>
    </StyledBackground>
  );
};

export default UploadPdf;
