import React,{useState} from 'react';
import { useHistory,useParams } from 'react-router';
import styled from 'styled-components';

import CreateNote from './createNote';
//icon 
import addIcon from '../assets/addIcon.svg';
import PdfIcon from '../assets/pdfIcon';
import NoteIcon from '../assets/noteIcon';
import BackIcon from '../assets/backIcon';

import {motion} from 'framer-motion'
import {darkBackgroundAni, fadeAni,fadeLeftStruggerAni,} from '../animation/animation'

const StyledNav = styled(motion.div)`
position: fixed;
top: 0;
left: 0;
height: 100vh;
width: 60px;
display: flex;
justify-content: center;
align-items: center;
z-index: 50;
flex-direction: column;
-webkit-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -moz-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  -o-box-shadow: 0px 0px 8px 2px rgba(32, 32, 32, 0.5);
  box-shadow: 0px 0px 10px 8px rgba(39, 39, 39, 0.5);
  svg {
    transition: all 0.3s;
    cursor: pointer;
    width: 60%;
    margin: 1rem 0;
    height: auto;
    :hover {
      fill-opacity: 0.8;
    }
  }
  @media (max-width: 838px){
width: 100vw;
height: 60px;
flex-direction: row;
svg{
    margin: 0 1rem;

    height: 60%;
    width: auto;
}
  }
`

const ArchiveNav = ({openUploadPdf}) =>{
const [createOpen, setCreateOpen] = useState(false);
    const history = useHistory();
    const {id} = useParams()


    const addClickHandler = () =>{
        openUploadPdf(true);
    }


    return(
        <>
        {(createOpen && <CreateNote open={setCreateOpen}/>)}
        <StyledNav initial="hide" animate="show" variants={fadeLeftStruggerAni}>
            <BackIcon fill={"#616161"} onClick={()=> history.goBack()}/>
            <PdfIcon fill={"#616161"} onClick={addClickHandler}/>
            <NoteIcon fill={"#616161"} onClick={() => setCreateOpen(true)}/>
       
        </StyledNav>
        </>
    )
}

export default ArchiveNav;