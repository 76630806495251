import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { v4 as uuidv4 } from "uuid";

const ReactComponent = ({ className, text, readOnly }) => {
  const [editMode, setEditMode] = useState(false);
  const [formula, setFormula] = useState(text);
  const tb = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const changeEditMode = () => {
    if (readOnly) return;
    setEditMode(true);
  };

  const config = {
    loader: { load: ["input/asciimath"] },
  };

  return (
    <div
      className={className}
    
      onBlur={() => setEditMode(false)}
      style={{display: "flex",justifyContent:"center" }}
      onClick={changeEditMode}
   
    >
      <p id="formula" style={{visibility: 'collapse', display: 'none'}}>{formula}</p>
      {editMode ? (
        <input
       
          input="ascii"
          type="text"
          ref={tb}
          autoFocus
          onBlur={() => setEditMode(false)}
          style={{
            width: "100%",
            background: "transparent",
            outline: "none",
            border: "0px",
            color: "white",
            textAlign: "center",
            letterSpacing: "1px",
          }}
          value={formula}
          onChange={(e) => {
            setFormula(e.target.value);
          }}
        />
      ) : (
        <div>
          {!formula ? (
            <p     onFocus={changeEditMode} contentEditable ={!readOnly}
            suppressContentEditableWarning={true}  loaded={changeEditMode}>Formula</p>
          ) : (
            <MathJaxContext config={config}>
              <MathJax>
                {"`"}
                {formula}
                {"`"}
              </MathJax>
            </MathJaxContext>
          )}
        </div>
      )}
    </div>
  );
};

export default class Formula {
  constructor({ data, api, config, readOnly }) {
    // provided by EditorJS
    this.api = api;
    this.config = config;
    this.data = data;
    this.readOnly = readOnly;

    // UI element rendered by the plugin/tool
    this.container = undefined;

    this._CSS = {
      block: this.api.styles.block,
      react: "react-component",
    };

    this.blockId = uuidv4();
  }

  render() {
    // ATTENTION: do not create the element twice!
    if (!this.container) {
      this.container = document.createElement("div");
      this.container.classList.add(this._CSS.block);

      ReactDOM.render(
        <ReactComponent
          {...this.data}
          className={this._CSS.react}
          blockId={this.blockId}
          readOnly={this.readOnly}
        />,
        this.container
      );
    }
    return this.container;
  }
  static get tune() {
    return {
      icon: '<i class="my-tool-icon">ƒ</i>',
      title: "Fromula",
    };
  }
  static get toolbox() {
    return {
      icon: '<i class="my-tool-icon">ƒ</i>',
      title: "Fromula",
    };
  }
  /**
   * LIFECYCLE HOOKS
   * @see https://editorjs.io/tools-api#lifecycle-hooks
   */

  static get isReadOnlySupported() {
    return true;
  }
  static get enableLineBreaks() {
    return false
  }
  /**
   * invoked by editorjs and should return current data of the block
   */
  save(element) {
    const reactComponent = element.firstChild;
    const formulaParagraph = reactComponent.firstChild
const formula =formulaParagraph.innerHTML
 
    return {
      text: formula
    };
  }
}
