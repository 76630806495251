import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { GET_FILE } from "../api";
import styled from "styled-components";
import BackIcon from "../assets/backIcon";

//redux
import { useSelector } from "react-redux";



const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  padding: 0vh 5vh;
  width: 100vw;
  overflow-x: hidden;
`;
const StyledNav = styled.div`
  z-index: 60;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 100px auto;
  top: 5vh;
  left: 10px;
  height: 90vh;
  border-radius: 10px;
  width: 60px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.3;
  transition: all 0.3s;
  flex-direction: column;
  transform: translateY(0px);

  transform: translateX(-30px);
  :hover {
    transform: translateX(0px);
    opacity: 1;
  }
  button {
    background: transparent;
    color: #ffffff84;
    border:none;
    padding: 1px;
    margin: 1rem 0;
    font-weight: 200;
    letter-spacing: 1px;
  }
  @media (max-width: 838px) {
    width: 90vw;
    height: 60px;
    left: 5vw;
    top: 10px;
    grid-template-rows: 1fr;
    grid-template-columns: 100px auto;
    justify-content: flex-start;
    transform: translateX(0px);

    transform: translateY(-30px);
    :hover {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;



const StyledNavRow = styled.div`
  p {
    font-size: 0.8rem;
    margin: 5px;
    text-align: center;
  }

  display: flex;
  align-items: center;
  justify-content: center;
width: 100%;
height: 100%;
`;

const StyledBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  svg {
    transition: all 0.3s;
    cursor: pointer;
    width: 60%;
    margin: 10px;
    :hover {
      fill-opacity: 0.8;
    }
  }
  @media (max-width: 838px) {
    flex-direction: row;
    height: 60px;
    width: auto;
    margin: 0 1rem;

    svg {
      height: 60%;
      width: auto;
    }
  }
`;

const CenterNav = styled.div`
  display: flex;
align-items: center;
  justify-content: center;

  width: 100%;
  flex-direction: column;
  @media (max-width: 838px){
    flex-direction: row;
  }
`
const PdfPage = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const { token } = useSelector((state) => state.user);
  const { id } = useParams();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const history = useHistory();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      <StyledNav>
      <StyledBlock>
          <BackIcon fill={"#616161"} onClick={() => history.goBack()}>{"Back"}</BackIcon>
        </StyledBlock>
        <CenterNav> 
        <StyledBlock>
          <button onClick={() => setZoom(zoom + 0.1)}>+</button>
          <p>{Math.floor(zoom * 100)}</p>
          <button onClick={() => setZoom(zoom - 0.1)}>-</button>
        </StyledBlock>
        <StyledBlock>
        <StyledNavRow>
          <button onClick={() => setPageNumber(pageNumber - 1)}>{"<"}</button>
       
 
          <p>
            {pageNumber} / {numPages}
          </p>
          <button onClick={() => setPageNumber(pageNumber + 1)}>{">"}</button>
        </StyledNavRow>
          </StyledBlock>
      

        </CenterNav>

       
      </StyledNav>
      <StyledPage>
        <Document
          file={{
            url: GET_FILE(id),
            httpHeaders: { "auth-token": token },
          }}
          renderMode="svg"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(err) => console.log(err)}
        >
          <Page
            scale={zoom}
            pageNumber={pageNumber}
            renderInteractiveForms={true}
            renderAnnotationLayer={true}
            renderTextLayer={true}
          />
        </Document>
      </StyledPage>
    </>
  );
};

export default PdfPage;

//<p>
//Page {pageNumber} of {numPages}
//</p>
