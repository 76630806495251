const initialState = {
    token: "",
    isLoading: false,
    loginError:"",
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA":
        return {
          ...state,
          token: action.payload.token,
          isLoading: false,
          loginError: action.payload.error
        };
        case "USER_LOADING":
        return{
          ...state,
          isLoading: true,
        };
        case "LOG_OUT":
          return{
            ...state,
            token: "",
         
          }
      default:
        return { ...state };
    }
  };
  
  export default userReducer;