import { GET_SUBJECT_ULR } from "../api";
import axios from "axios";

export const getSubjectList = (jsToken) => async (dispatch) => {
  //FETCH AXIOS


  dispatch({
    type: "SUBJECT_LOADING",
  });

  const res = await axios.get(
    GET_SUBJECT_ULR(),
    { headers: { "auth-token": jsToken } }
  );

  dispatch({
    type: "GET_SUBJECT",
    payload: {
      subjectList: res.data,
    },
  });
};
