import { LOGIN_ULR } from "../api";
import axios from "axios";

export const tryLogin = (pwd) => async (dispatch) => {
  //FETCH AXIOS
  dispatch({
    type: "USER_LOADING",
  });

  const dataBody = {
    password: pwd,
  };



  try {
    const response = await axios
      .post(LOGIN_ULR(), dataBody)

      dispatch({
        type: "GET_DATA",
        payload: {
          token: response.data.token,
          error: "",
        },
      });
  } catch (error) {

    console.log(error.response);
    const message = error.response === undefined ? "server offline" : (error.response.data)
    dispatch({
      type: "GET_DATA",
      payload: {
        token: "",
        error: message
      },
    });
  }

 
};
